<template>
  <b-container
    id="options-slide"
    class="container-fluid"
  >
    <slide-heading
      :heading="currentSlide.heading"
    />

    <div class="slide-description mb-2 justify-content-center">
      <div v-html="currentSlideDescription" />
    </div>

    <div v-if="loaded" class="slide-options mt-5 mb-5">
      <option-button
        v-for="option in currentSlide.options.options"
        :key="option.value"
        :option="option"
        @click.native="submitOption(option)"
      />
    </div>

    <ct-centered-spinner v-else>
      Submitting...
    </ct-centered-spinner>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'OptionsSlide',
  components: {
    CtCenteredSpinner:         () => import('@/components/shared/CtCenteredSpinner'),
    OptionButton:              () => import('@/components/StagelineV2/shared/OptionButton'),
    SlideHeading:              () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  data() {
    return {
      loaded: true,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'currentSlide',
      'stagelineConfig',
    ]),
    currentSlideDescription() {
      return DOMPurify.sanitize(this.currentSlide.description)
    },
  },
  methods: {
    ...mapActions('companies', [
      'updateCompanyConfig',
    ]),
    async submitOption(option) {
      this.loaded = false

      const namespace = this.currentSlide.options.key.namespace
      const key       = this.currentSlide.options.key.name
      const value     = option.value

      await this.updateCompanyConfig({
        companyId: this.company.id,
        config: { [namespace]: { [key]: value } },
      })

      this.$emit('next-slide')
    },
  },
}
</script>

<style scoped lang="scss">
#options-slide {
  .slide-options {
    > * {
      margin-bottom: 1rem;
    }
  }
}

</style>
